import {createContext, useContext, useState} from 'react'
import {useStatus} from './useStatus'

export const BuildVersionContext = createContext<string | undefined>(undefined)

export function useBuildVersion() {
  const initialBuildVersion = useContext(BuildVersionContext)
  const [localBuildVersion] = useState(initialBuildVersion ?? 'development')
  const status = useStatus()
  return {localBuildVersion, remoteBuildVersion: status?.buildVersion}
}
